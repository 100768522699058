import React, { useState, useEffect } from "react";
import logo from "./logo.png";
import background from "./background2.png";
import loading from "./loading.gif";
import "./App.css";
import ImgDropAndCrop from "./components/fileupload";
import MessageBox from "./components/messageBox"
import DropzoneAreaExample from "./components/dropzone";
import { DropzoneArea } from "material-ui-dropzone";
import { DropzoneDialogBase } from "material-ui-dropzone";
import { DropzoneDialog } from "material-ui-dropzone";
import {
    CardHeader,
    CardActionArea,
    CardMedia,
    Grid,
    Card,
    CardContent,
    Button,
    Box
} from "@material-ui/core";
import { TextField, Paper, Container, Typography } from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import theme from "./theme";
import { fileSubmit } from "./helpers";

const images = require.context('../src', true);

// adding dynamic paths


const encode = (data) => {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
};

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        background: { background }, //"#E8F2EA",
        padding: 20
    },
    section_header: {
        marginTop: 20,
    },
    card: {
        margin: 10,
        height: 250,
        raised: true,
        textAlign: "center",
    },
    textarea: {
  width: 300,
  height: 150
},
    comment_card: {
        margin: 20,
        padding: 20,
        height: 400,
        raised: true,
        textAlign: "center",
    },
    inspiration_card: {
        margin: 10,
        //height: 100,
        raised: true,
        textAlign: "center",
    },
    button: {
        margin: 10,
    },
    comments: {
        margin: 10,
        height: 220,
        raised: true,
        padding: 20,
        textAlign: "left",
        //backgroundColor: '#E8F2EA'
    },

    card_image: {
        display: "block",
        margin: "auto",
    },
    header_image: {
        display: "block",
        margin: "auto",
        padding: 20,
    },
    control: {
        padding: theme.spacing(2),
    }
    
});

const handleSubmit = (e) => {
    fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "message_form", ...this.state }),
        })
        .then(() => alert("Success!"))
        .catch((error) => alert(error));

    e.preventDefault();
};

const handleChange = (e) =>
    this.setState({
        [e.target.name]: e.target.value,
    });

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function App(props) {
    useEffect(() => {
        var url_string = window.location.href;
        var url = new URL(url_string);
        var id = url.searchParams.get("id");
        setId(id);

        fetch("/.netlify/functions/functionName?id=" + id)
            .then((res) => res.json())
            .then((json) => {
                setCloseId(json["close_id"]);
                setPhotos(json["Real Brides Photos"]);
                setStyledshoot(json["Styled Shoot Photos"]);
                setSketches(json["Sketches Photos"]);
                setInspiration_photos(json["Inspiration Photos"]);
                setCustom_photos(json["Custom Photos"]);
                setLace(json["Lace Photos"]);
                setBTS(json["Production Photos"]);
                setIsLoaded(true);
                setCustomer_name(json["Name"]);
                setMessage(json["Message"]);
                setDesignerComments(json["Designer Comments"]);
                setDesigner(json["Designer"]);
                setDesignerPhoto(images('./'+json["Designer"]+'.png'));                

            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const [open, setOpen] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [count, setCount] = useState(0);
    const [email, setEmail] = useState("");
    const [id, setId] = useState("");
    const [closeId, setCloseId] = useState("");
    const [customer_name, setCustomer_name] = useState("");
    const [message, setMessage] = useState("");
    const [designerComments, setDesignerComments] = useState("");
    const [designer, setDesigner] = useState("");
    const [designerPhoto, setDesignerPhoto] = useState("");
    const [custom_photos, setCustom_photos] = useState("");

    const [inspiration_photos, setInspiration_photos] = useState({});
    const [photos, setPhotos] = useState({});
    const [styledshoot, setStyledshoot] = useState({});
    const [sketches, setSketches] = useState({});
    const [bts, setBTS] = useState({});
    const [lace, setLace] = useState({});

    const classes = useStyles();

    if (!isLoaded)
        return (
            <ThemeProvider theme={theme}>
        <div
          className={classes.root}
          style={{
            background: "#E8F2EA",
            //background: `url(${background})`,
          }}
        >
          <Container
            maxWidth="m"
            style={{
              background: "#F2F2F2",
              height: "100%"
            }}
          >
            <img className={classes.header_image} src={logo} alt="Logo"/>
            <img className={classes.header_image} src={loading} alt="Logo" />
            <Typography color="primary" style={{ textAlign: "center" }}>
              Loading...
            </Typography>
          </Container>
        </div>
      </ThemeProvider>
        );

    return (
        <ThemeProvider theme={theme}>
      <div
        className={classes.root}
        style={{
          background: "#E8F2EA",
          
        }}
      >
        <Container
          maxHeight="100%"
          maxWidth="m"
          style={{
            background: "#F2F2F2",
          }}
        >
          <img className={classes.header_image} src={logo} alt="Logo" />

              <Grid container>
                          <Grid item xs={12} sm={6}>

            <Card
              color="primary"
              style={{
                borderColor: "#E8F2EA",
                borderRadius: 10,
              }}
              className={classes.comment_card}
            >
              <b>{customer_name}</b>
              <MessageBox
                id={id}
                message={message}
                closeId = {closeId}
                />
              
            </Card>{" "}
            </Grid>
            <Grid item xs={12} sm={6}>
            <Card
              color="primary"
              style={{
                borderColor: "#E8F2EA",
                borderRadius: 10,
              }}
              className={classes.comment_card}
            >


                    <Typography color="primary">A note from your designer, {designer}:</Typography>{" "}
                    <TextField
                    inputProps={{ readOnly: true,style: { fontSize: "0.8rem" } }}
                      name="message"
                      multiline
                      fullWidth
                      rows={8}
                      defaultValue={designerComments}
                      variant="outlined"
                    />  
            
            <img src={designerPhoto} width='100px' padding='50px'></img>
            <br/>
            <Button 
                  onClick={() => { window.open('https://laceandliberty.as.me/?appointmentType=category:Followup%20Call') }}
                  className={classes.button}
                  variant="contained"
                  color="primary">
                  Book Designer Call
                </Button>

            </Card>{" "}
            </Grid>
          
          </Grid>

          <Typography className={classes.section_header} color="primary">
            INSPIRATION
          </Typography>
          <Grid container>
            <Card className={classes.inspiration_card}>
              <CardHeader
                className={classes.title}
                title={
                  <Typography className={classes.title} color="primary">
                    Inspiration Photo Upload
                  </Typography>
                }
                subheader=""
              />
              <CardContent>
                <Typography wrap>
                  Upload your inspiration and any photos of yourself in dresses here. Let us know in the comment section your thoughts in more detail. 
                </Typography>
              </CardContent>
              <div>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={() => setOpen(true)}                  
                >
                  Add Image
                </Button>
                

                <DropzoneDialog
                  acceptedFiles={["image/*"]}
                  cancelButtonText={"cancel"}
                  submitButtonText={"submit"}
                  maxFileSize={5000000}
                  open={open}
                  onClose={() => setOpen(false)}
                  onSave={(files) => {
                    console.log("Files: ", files);
                    setIsLoaded(false);
                    fileSubmit(files, id, function (picture) {
                      window.location.reload(true)
                      //setTimeout(() => {
                        //setIsLoaded(true);
                        //window.location.reload(true)
                      //}, 1000);
                    });
                    setOpen(false);
                  }}
                  showPreviews={true}
                  showFileNamesInPreview={true}
                />
              </div>
            </Card>
            {inspiration_photos &&
              inspiration_photos.map((photo) => (
                <Grid item xs={12} sm={6} md={4}>
                  <Card variant="outlined" className={classes.card}>
                    <CardHeader
                      title=""
                      subheader={"Inspiration " + photo.id}
                    />
                    <a href={photo.url} target="_blank">
                    <CardMedia
                      style={{ height: "100%" }}
                      image={photo.url}
                      title="Photo"
                      className={classes.card_image}
                    />
                    </a>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      ></Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
          
          <Typography className={classes.section_header} color="primary">
            REAL BRIDE PHOTOS - click to enlarge
          </Typography>
          <Grid container>
            {photos &&
              photos.map((photo) => (
                <Grid item xs={12} sm={6} md={4}>
                  <Card variant="outlined" className={classes.card}>
                    <CardHeader title={photo.id} subheader="" />
                    <a href={photo.thumbnails.large.url} target="_blank">
                    <CardMedia
                      style={{ width: 150, paddingTop: "50%" }}
                      image={photo.thumbnails.large.url}
                      title="Photo"
                      className={classes.card_image}
                    />
                    </a>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        {photo.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>

          <Typography className={classes.section_header} color="primary">
            SHOOT PHOTOS - click to enlarge
          </Typography>
          <Grid container>
            {styledshoot &&
              styledshoot.map((photo) => (
                <Grid item xs={12} sm={6} md={4}>
                  <Card variant="outlined" className={classes.card}>
                    <CardHeader title={photo.id} subheader="" />
                    <a href={photo.thumbnails.large.url} target="_blank">
                    <CardMedia
                      style={{ width: 150, paddingTop: "50%" }}
                      image={photo.thumbnails.large.url}
                      title="Photo"
                      className={classes.card_image}
                    />
                    </a>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        {photo.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>

          <Typography className={classes.section_header} color="primary">
            SKETCHES - click to enlarge
          </Typography>
          <Grid container>
            {sketches &&
              sketches.map((photo) => (
                <Grid item xs={12} sm={6} md={4}>
                  <Card variant="outlined" className={classes.card}>
                    <CardHeader title={"S" + photo.id} subheader="" />
                    <a href={photo.thumbnails.large.url} target="_blank">
                    <CardMedia
                      style={{ width: 120, paddingTop: "56%" }}
                      image={photo.thumbnails.large.url}
                      title="Sketch"
                      className={classes.card_image}
                    />
                    </a>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        {photo.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>

          <Typography className={classes.section_header} color="primary">
            LACE - click to enlarge 
          </Typography>
          <Grid container>
            {lace &&
              lace.map((photo) => (
                <Grid item xs={12} sm={6} md={4}>
                  <Card variant="outlined" className={classes.card}>
                    <CardHeader title={"L" + photo.id} subheader="" />
                    <a href={photo.thumbnails.large.url} target="_blank">
                    <CardMedia
                      style={{ width: 120, paddingTop: "56%" }}
                      image={photo.thumbnails.large.url}
                      title="Lace"
                      className={classes.card_image}
                    />
                    </a>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        {photo.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>

           <Typography className={classes.section_header} color="primary">
            PRODUCTION PHOTOS - click to enlarge
          </Typography>
          <Grid container>
            {bts &&
              bts.map((photo) => (
                <Grid item xs={12} sm={6} md={4}>
                  <Card variant="outlined" className={classes.card}>
                    <CardHeader title={"S" + photo.id} subheader="" />
                    <a href={photo.thumbnails.large.url} target="_blank">
                    <CardMedia
                      style={{ width: 120, paddingTop: "56%" }}
                      image={photo.thumbnails.large.url}
                      title="Behind The Scenes"
                      className={classes.card_image}
                    />
                    </a>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        {photo.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>

           <Typography className={classes.section_header} color="primary">
            OTHER PHOTOS - click to enlarge
          </Typography>
          <Grid container>
            {custom_photos &&
              custom_photos.map((photo) => (
                <Grid item xs={12} sm={6} md={4}>
                  <Card variant="outlined" className={classes.card}>
                    <a href={photo.thumbnails.large.url} target="_blank">
                    <CardMedia
                      style={{ width: 120, paddingTop: "56%" }}
                      image={photo.thumbnails.large.url}
                      title="Behind The Scenes"
                      className={classes.card_image}
                    />
                    </a>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        {photo.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>

        </Container>
      </div>
    </ThemeProvider>
    );
}
export default App;