import { createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';

import BellWoff2 from './fonts/Bell-Med.woff2';
import CeraGRWoff2 from './fonts/CeraGR-Regular.woff2'


const ceraGR = {
  fontFamily: 'CeraGR',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('CeraGR'),
    local('CeraWoff2-Regular'),
    url(${CeraGRWoff2}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const Bell = {
  fontFamily: 'Bell',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Bell'),
    local('Bell-Med'),
    url(${BellWoff2}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = createMuiTheme({
  typography: {
    fontFamily: 'CeraGR',
  },
  label: {
  	    fontFamily: 'CeraGR',
  	},
  palette: {
    primary: {
      main: "#D1914C",
    },
    secondary: {
      main: "#7C2344",
    },
  },
});

export default theme;