import {base64StringtoFile,
    downloadBase64File,
    extractImageFileExtensionFromBase64,
    image64toCanvasRef} from './components/ResuableUtils'

export function fileSubmit(files,id,callback) {

 const currentFile = files[0]
    const myFileItemReader = new FileReader()
    myFileItemReader.addEventListener("load", () => {
        // console.log(myFileItemReader.result)
        const myResult = myFileItemReader.result

        fetch('/.netlify/functions/uploadImage?id='+id, {
                method: 'POST',
                body: JSON.stringify({
                    imageBase64: myResult
                })
            })
            .then(res => res.json())
            .then(json => {
                console.log(json)
               	callback(true);
            }).catch((err) => {
                console.log(err);
            })

    }, false)

    myFileItemReader.readAsDataURL(currentFile)

}