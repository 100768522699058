import React, { useState } from 'react';
import { TextField, Box, Button, Typography } from "@material-ui/core";


export default function MessageBox(props) {
    const [message, setMessage] = useState('');

    const id = props.id;
    const closeId = props.closeId;
    
    const submitMessage = async (e) => {
        e.preventDefault();
        console.log(id)
        try {
            await fetch('/.netlify/functions/updateComment', {
                method: 'POST',
                body: JSON.stringify({
                    id,
                    message,
                    closeId
                }),
            });
            var btn = document.getElementById("test");
            btn.innerHTML = 'Message Received. Click here to update';
        } catch (err) {
            console.error(err);
        }
    };


    return (
        
        <form method="post" onSubmit={submitMessage}>
                
                <p>
                  <label>
                    <Typography color="primary">Bride Comments:</Typography>
                    <Typography>
                    <Box fontSize={9} m={1}>
        Please comment here on your dress vision and any details you'd like to share with us. After uploading your inspiration photos, please refer to each photo by number and let us know what aspects of it that you specifically like. 
      </Box>
                    </Typography>
                    <TextField  
                      inputProps={{ style: { fontSize: "0.8rem" } }}
                      name="message"
                      multiline
                      fullWidth
                      rows={9}
                      defaultValue={props.message}
                      variant="outlined"
                      onChange={(e) => setMessage(e.target.value)}

                    />
                  </label>
                </p>

                <p>
                  <Button id="test" variant="outlined" color="primary" type="submit">
                    Send
                  </Button>
                </p>
              </form>

        
    );
}
